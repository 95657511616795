import { Button } from '@mui/material'
import React from 'react'
import "./contactBar.css"
import { Link } from 'react-router-dom'
import { phoneNumber } from '../../constant'

function ContactBar() {
  return (
    <div className='req-call-wp'>
            <Button className='req-call-btn' >
            {/* <span className="material-symbols-outlined">
phone_in_talk
</span> */}
<img src='../../../img/whatsAppIcon.png' className='whatsAppIcon' />
                <Link target='_blank' to={"https://wa.me/"+phoneNumber+"?text=Hi..."} className='text'>
                
                    Contact
                </Link>
            </Button>
            </div>
  )
}

export default ContactBar