import React from 'react'
import "./clientSection.css"

function Glidar({style, headerColor, content=[], title}){
    let speed = content.length*3;
    return (
        <div style={{...style}} className='glidar'>
            <div style={{backgroundColor: headerColor}} className='header'>
                <h4>{title}</h4>
            </div>
            <div className='slidar'>
                <div style={{animation: `scroll ${speed}s linear infinite`}} className='slidar-track'>
                
                    {
                        content?.map((item, index)=>(
                            <div key={index} className='silde-box'>
                        <img loading='lazy' className='silde-box-img' src={item} alt='client_img' />
                    </div>
                        ))
                    }
                    {
                        content?.map((item, index)=>(
                            <div  key={index} className='silde-box'>
                        <img loading='lazy' className='silde-box-img' src={item} alt='client_img' />
                    </div>
                        ))
                    }
                    

                    
                </div>
            </div>
        </div>
    )
}

function ClientSection() {
    const HotelsClientArr = [
        "../../../../img/client_logos/hotel/hotle1.png",
        "../../../../img/client_logos/hotel/hotle2.png",
        "../../../../img/client_logos/hotel/hotle3.png",
        "../../../../img/client_logos/hotel/hotle4.png",
        "../../../../img/client_logos/hotel/hotle5.png",
        "../../../../img/client_logos/hotel/hotle6.jpg",
        "../../../../img/client_logos/hotel/hotle7.png",
        "../../../../img/client_logos/hotel/hotle8.png",
        "../../../../img/client_logos/hotel/hotle9.jpg",
        "../../../../img/client_logos/hotel/hotle10.svg",
        "../../../../img/client_logos/hotel/hotle11.jpg",
        "../../../../img/client_logos/hotel/hotle12.png",
        "../../../../img/client_logos/hotel/hotle13.jpg"
    ]
    const AirlineClientArr = [
        "../../../../img/client_logos/railway/railway1.png",
        "../../../../img/client_logos/railway/railway2.png",
        "../../../../img/client_logos/airlines logo/airline1.png",
        "../../../../img/client_logos/airlines logo/airline2.png",
        "../../../../img/client_logos/airlines logo/airline3.png",
        "../../../../img/client_logos/airlines logo/airline4.png",
        "../../../../img/client_logos/airlines logo/airline5.png",
        "../../../../img/client_logos/airlines logo/airline6.png",
        "../../../../img/client_logos/airlines logo/airline7.png",
        "../../../../img/client_logos/airlines logo/airline8.png",
        "../../../../img/client_logos/airlines logo/airline9.png",
        "../../../../img/client_logos/airlines logo/airline10.png",
        "../../../../img/client_logos/airlines logo/airline11.png",
        "../../../../img/client_logos/airlines logo/airline12.png",
        "../../../../img/client_logos/airlines logo/airline13.png",
        "../../../../img/client_logos/airlines logo/airline14.png",
        "../../../../img/client_logos/airlines logo/airline15.png"
    ]

    const cruiseClientArr = [
        "../../../../img/client_logos/cruise/cruise1.jpg",
        "../../../../img/client_logos/cruise/cruise2.png",
        "../../../../img/client_logos/cruise/cruise3.png",
        "../../../../img/client_logos/cruise/cruise4.webp",
        "../../../../img/client_logos/cruise/cruise5.png"
    ]

    const othersArr = [
        "../../../../img/client_logos/Corporate/client1.png",
        "../../../../img/client_logos/Corporate/client2.png",
        "../../../../img/client_logos/Corporate/client3.png",
        "../../../../img/client_logos/Corporate/client4.png",
        // "../../../../img/client_logos/Corporate/client5.png",
        "../../../../img/client_logos/Corporate/client6.png",
        "../../../../img/client_logos/Corporate/client7.png",
        "../../../../img/client_logos/Corporate/client8.png",
        "../../../../img/client_logos/Corporate/client9.png",
        "../../../../img/client_logos/Corporate/client10.png",
        "../../../../img/client_logos/Corporate/client11.png",
        "../../../../img/client_logos/Corporate/client12.jpeg"
    ]
  return (
    <div id='ClientSection' className='clientSection'>


<div className='client-scroller-wp'>
    <div className='header'>
        <h4 className='text'>Collaboration</h4>
    </div>
    <Glidar title={"Hotels Partner"} content={HotelsClientArr} headerColor={"#ffd382"} style={{marginTop: "50px"}} />
    <Glidar title={"Airline Partner"} content={AirlineClientArr} headerColor={"#f2c3b8"} style={{marginTop: "50px"}} />
    <Glidar title={"Cruise Partner"} content={cruiseClientArr} headerColor={"#c2e4f2"} style={{marginTop: "50px"}} />
    <Glidar title={"Clients"} content={othersArr} headerColor={"#bab5b6"} style={{marginTop: "50px"}} />
</div>

    </div>
  )
}

export default ClientSection