import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import "./searchInput.css"
export default function SearchInput({searchList, set_searchTerm}) {
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        set_searchTerm(value)
    }, [value])
    

  return (
    <Stack spacing={2} sx={{ width: "40%" }}>

      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          if(newInputValue === "") set_searchTerm("")
        }}
        disableClearable
        options={searchList.map((option) => option)}
        renderInput={(params) => (
          <TextField
        
            {...params}
            label=<span className='search-lable'><span class="material-symbols-outlined">
search
</span>&nbsp;&nbsp;Search</span>
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Stack>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { term: 'The Shawshank Redemption', year: 1994 },
  { term: 'The Godfather', year: 1972 },
  { term: 'The Godfather: Part II', year: 1974 },
  { term: 'The Dark Knight', year: 2008 },
  { term: '12 Angry Men', year: 1957 },
  { term: "Schindler's List", year: 1993 },
  { term: 'Pulp Fiction', year: 1994 },
  {
    term: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { term: 'The Good, the Bad and the Ugly', year: 1966 },
  { term: 'Fight Club', year: 1999 },
  {
    term: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    term: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { term: 'Forrest Gump', year: 1994 },
  { term: 'Inception', year: 2010 },
  {
    term: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { term: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { term: 'Goodfellas', year: 1990 },
  { term: 'The Matrix', year: 1999 },
  { term: 'Seven Samurai', year: 1954 },
  {
    term: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { term: 'City of God', year: 2002 },
  { term: 'Se7en', year: 1995 },
  { term: 'The Silence of the Lambs', year: 1991 },
  { term: "It's a Wonderful Life", year: 1946 },
  { term: 'Life Is Beautiful', year: 1997 },
  { term: 'The Usual Suspects', year: 1995 },
  { term: 'Léon: The Professional', year: 1994 },
  { term: 'Spirited Away', year: 2001 },
  { term: 'Saving Private Ryan', year: 1998 },
  { term: 'Once Upon a Time in the West', year: 1968 },
  { term: 'American History X', year: 1998 },
  { term: 'Interstellar', year: 2014 },
  { term: 'Casablanca', year: 1942 },
  { term: 'City Lights', year: 1931 },
  { term: 'Psycho', year: 1960 },
  { term: 'The Green Mile', year: 1999 },
  { term: 'The Intouchables', year: 2011 },
  { term: 'Modern Times', year: 1936 },
  { term: 'Raiders of the Lost Ark', year: 1981 },
  { term: 'Rear Window', year: 1954 },
  { term: 'The Pianist', year: 2002 },
  { term: 'The Departed', year: 2006 },
  { term: 'Terminator 2: Judgment Day', year: 1991 },
  { term: 'Back to the Future', year: 1985 },
  { term: 'Whiplash', year: 2014 },
  { term: 'Gladiator', year: 2000 },
  { term: 'Memento', year: 2000 },
  { term: 'The Prestige', year: 2006 },
  { term: 'The Lion King', year: 1994 },
  { term: 'Apocalypse Now', year: 1979 },
  { term: 'Alien', year: 1979 },
  { term: 'Sunset Boulevard', year: 1950 },
  {
    term: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { term: 'The Great Dictator', year: 1940 },
  { term: 'Cinema Paradiso', year: 1988 },
  { term: 'The Lives of Others', year: 2006 },
  { term: 'Grave of the Fireflies', year: 1988 },
  { term: 'Paths of Glory', year: 1957 },
  { term: 'Django Unchained', year: 2012 },
  { term: 'The Shining', year: 1980 },
  { term: 'WALL·E', year: 2008 },
  { term: 'American Beauty', year: 1999 },
  { term: 'The Dark Knight Rises', year: 2012 },
  { term: 'Princess Mononoke', year: 1997 },
  { term: 'Aliens', year: 1986 },
  { term: 'Oldboy', year: 2003 },
  { term: 'Once Upon a Time in America', year: 1984 },
  { term: 'Witness for the Prosecution', year: 1957 },
  { term: 'Das Boot', year: 1981 },
  { term: 'Citizen Kane', year: 1941 },
  { term: 'North by Northwest', year: 1959 },
  { term: 'Vertigo', year: 1958 },
  {
    term: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { term: 'Reservoir Dogs', year: 1992 },
  { term: 'Braveheart', year: 1995 },
  { term: 'M', year: 1931 },
  { term: 'Requiem for a Dream', year: 2000 },
  { term: 'Amélie', year: 2001 },
  { term: 'A Clockwork Orange', year: 1971 },
  { term: 'Like Stars on Earth', year: 2007 },
  { term: 'Taxi Driver', year: 1976 },
  { term: 'Lawrence of Arabia', year: 1962 },
  { term: 'Double Indemnity', year: 1944 },
  {
    term: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { term: 'Amadeus', year: 1984 },
  { term: 'To Kill a Mockingbird', year: 1962 },
  { term: 'Toy Story 3', year: 2010 },
  { term: 'Logan', year: 2017 },
  { term: 'Full Metal Jacket', year: 1987 },
  { term: 'Dangal', year: 2016 },
  { term: 'The Sting', year: 1973 },
  { term: '2001: A Space Odyssey', year: 1968 },
  { term: "Singin' in the Rain", year: 1952 },
  { term: 'Toy Story', year: 1995 },
  { term: 'Bicycle Thieves', year: 1948 },
  { term: 'The Kid', year: 1921 },
  { term: 'Inglourious Basterds', year: 2009 },
  { term: 'Snatch', year: 2000 },
  { term: '3 Idiots', year: 2009 },
  { term: 'Monty Python and the Holy Grail', year: 1975 },
];