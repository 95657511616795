import React from 'react'
import "./videoSection.css"
function VideoSection() {


  return (
    <div className='videoSection' >
        <video 
        autoPlay
        muted
         controls>
        <source src="../../../../vid/vaanyamVideo.webm" type="video/mp4" />
        </video>
    </div>
  )
}

export default VideoSection