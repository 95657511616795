import React, { useState } from 'react'
import "./destCard.css"
import { Button, Skeleton } from '@mui/material'
import PopUp from '../popUp/PopUp'
import { Host, phoneNumber } from '../../constant'
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tag from '../tag/Tag'

import Box from '@mui/material/Box';



// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const IconTag = ({ type }) => {

  let iconName = ""
  if (type === "Arrival") iconName = "arrival_icon"
  else if (type === "Transfers") iconName = "car_icon"
  else if (type === "Stay Included") iconName = "stay_icon"
  else if (type === "Sightseeing") iconName = "sightseeing_icon"
  else iconName = "food_icon"
  return (
    <div className='it-icon-box'>
      <img className='it-icon' src={'../../../img/icon/' + iconName + '.png'} alt='icons' />
      <p>{type}</p>
    </div>
  )
}

export const PopUpButtom = () => {
  // <Button onClick={()=>{
  //     window.open("https://wa.me/"+phoneNumber)
  // }}></Button>
  return <Button className='req-call-btn' >

    <img src='../../../img/whatsAppIcon.png' className='whatsAppIcon' alt='destination image' />
    <Link target='_blank' to={"https://wa.me/" + phoneNumber + "?text=Hi..."} className='text'>

      Contact
    </Link>
  </Button>
}





function DestCard({ data, empty }) {






  const { itinary } = data;
  console.log(itinary, data, "oooooo");
  const [open, set_Open] = useState(false)


console.log("kkkkkkkkkk");

  return (
    <div className='destCard lora-font'>
      {!empty? <>
        <img loading='lazy' className='dc-img' src={Host+"/temp/"+data?.imagePath}
        alt='destinations image' />
      <div className='dc-content-wp'>
        <h1 className='dc-title'>{data?.place}</h1>
        <p className='dc-place'>
          <span class="material-symbols-outlined">
            location_on
          </span>
          {data?.map}
        </p>
        <div className='dc-booknow-wp'>
          <Button onClick={() => set_Open(true)} className='nav-contactBtn dc-booknow-btn'>Learn more</Button>

        </div>
      </div>
      </>
      :
      <>
      <Skeleton sx={{borderRadius: "25px"}} variant="rectangular" width={"100%"} height={"50%"} />
      <Box sx={{ pt: 0.5 }}>
              <Skeleton sx={{borderRadius: "10px"}} height={"80px"} />
              <Skeleton sx={{borderRadius: "10px"}} height={"40px"} width="60%" />
            </Box>
      </>
      
      }



      <PopUp title={data.place} open={open} onClose={()=>set_Open(false)} PopUpButtom={PopUpButtom} >
        <div className='destDetail lora-font'>
          <img className='dc-popup-img' src={Host+"/temp/"+data?.imagePath} alt="destination Image" />

          <div className='content-box' >

            <h1 className='content-title'>About</h1>
            <hr className='style-two'></hr>
            <p className='content-body roboto-font'>{data?.about}</p>
          </div>

          <div className='content-box'>

            <h1 className='content-title right-side'>Best Time To Visit</h1>
            <hr className='style-two'></hr>
            <p className='content-body roboto-font'>{data?.bestTimeVisit}</p>
          </div>

          <div className='content-box'>
            <h1 className='content-title'>Tourist Attractions</h1>
            <hr className='style-two'></hr>
            {/* <hr className='style-two'></hr> */}
            <p className='content-body roboto-font'>{data?.touristAttractions}</p>
          </div>

          
          <div className='content-box'>
            <h1 className='content-title'>Itinary  <span className='roboto-font'>{"( "}{itinary?.day} Day/ {itinary?.night} Night {")"}</span></h1>
            <p className='content-sub-title roboto-font'>For Coustom Package Contact Us !</p>
            <hr className='style-two'></hr>
            {/* <hr className='style-two'></hr> */}
            <div>
              {itinary?.detail?.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<span class="material-symbols-outlined">
                      expand_circle_up
                    </span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div className='it-header-wp roboto-font'>
                      <div className='it-header'>
                        <h4>Day {index + 1}</h4>
                        <p>{item?.title1}</p>
                      </div>
                      <div className='it-tags-wp'>
                        {
                          item?.tags?.split(",")?.map((tagName, tagIndex) => (
                            <Tag key={tagIndex} text={tagName} />
                          ))
                        }
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='it-body roboto-font'>
                      <h4 >{item?.title2}</h4>
                      <div className='it-icons-wp'>
                        {
                          item?.icons?.split(",")?.map((iconName, iconIndex) => (
                            <IconTag key={iconIndex} type={iconName} />
                          ))
                        }
                      </div>
                      {
                        item?.texts?.split("\n")?.map((txt, index) => (
                          <p key={index}>
                            {txt}
                          </p>
                        ))
                      }

                      <div className='multi-img-wp'>
                      {
                        item?.imgList?.map((imgName, imgIndex) => (
                          <img key={imgIndex} src={Host+"/temp/"+imgName} alt='img' />
                        ))
                      }
                      </div>

                    </div>
                  </AccordionDetails>
                </Accordion>

              ))}




            </div>
          </div>




        </div>
      </PopUp>
    </div>
  )
}

export default DestCard

