import React from 'react'
import "./banner.css"

function Banner() {
  return (
    <section className='mice-banner'>
    <div className='bg-dark'></div>
        <div className='content-wp'>
        
            <div className='companyLogo'>
                <img src='../../../../img/brandLogo.png' alt='companyLogo' />
            </div>
            <p className='company-name'>VAANYAM EXPERIENCES PVT LTD</p>
        </div>
    </section>
  )
}

export default Banner