export const phoneNumber = "919883107813"
export const FbUrl = "#bn"
export const LinkdinUrl = "#"
export const XUrl = "#"

// export const API_PREFIX="http://localhost:8080/api/v1"
export const API_PREFIX="https://vaanyam-backend2.onrender.com/api/v1"

// export const Host = "http://localhost:8080/"
export const Host = "https://vaanyam-backend2.onrender.com"
