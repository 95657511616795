import React from 'react'
import "./benifits.css"
import PhotoCard from '../../../components/photoCard/PhotoCard'
function Benifits() {
    const dataArr = [
        {
            title: "CORPORATE",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_1.webp",
            points: [
                "MEETING",
                "INCENTIVE",
                "CONFERECNE",
                "EVENT"
            ]
        },
        {
            title: "LEISURE",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_2.webp",
            points: [
                "DONESTIC",
                "INTERNATIONAL",
            ]
        },
        {
            title: "INBOUND TOUR",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_5.webp",
            points: [
                "EUROPE & AMERICAN TOURIST",
            ]
        },
        {
            title: "CUSTAMIZED",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_4.webp",
            points: [
                "YOUR PREFERED LOCATION & OUR EXPERIMENTS",
            ]
        },
        
        {
            title: "THEME BASED",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_3.webp",
            points: [
                "TREKKING",
                "WOMEN SPECIAL",
                "BIKE RIDE",
                "PILGRIMAGE TOUR",
                "SPORTS TOUR",
                "EDUCATIONAL TOUR",
                "BATCHELORS PARTY"
            ]
        },
        {
            title: "MEETING",
            imgPath: "../../../../mice/benifitSection/benifitSection_img_6.webp",
            points: [
                "Board meetings",
                "Shareholders meeting",
                "Management meeting",
                "Training seminar",
            ]
        }
        
    ]
  return (
    <>
    <div className='curve-top'></div>
        <section className='benifits-section'>
        <div className='header'>
        <h1 >CORPORATE / MICE</h1>
        <p>Vaanyam MICE is potent set-up that has various growth plan in the field of corporate mice & destination management. The entire set up is managed by highly professional & experienced personal. We always have been successful in meeting the corporate’s requirement who wanted to arrange any meeting, incentive, conference & events.</p>
        </div>
        {/* <div className='flex-wp'>
            <div className='content-wp'>
                <h1>CORPORATE</h1>
                <ul>
                    <li>MEETING</li>
                    <li>INCENTIVE</li>
                    <li>CONFERECNE</li>
                    <li>EVENT</li>
                </ul>
            </div>
            <div className='image-wp'>
                <PhotoCard imgPath="../../../../mice/benifitSection/benifitSection_img_1.webp" />
            </div>
        </div> */}
        {/* {
            dataArr?.map((item, index)=>(
                <div key={index} style={{flexDirection: index%2 === 0 ?"row":"row-reverse"}} className='flex-wp'>
            <div style={{alignItems: index%2 === 0 ?"flex-end":"flex-start"}} className='content-wp'>
                <h1>{item?.title}</h1>
                <ul>
                    {
                        item?.points?.map((item, index)=>(
                            <li key={index}>{item}</li>
                        ))
                    }
                </ul>
            </div>
            <div className='image-wp'>
                <PhotoCard imgPath={item?.imgPath} />
            </div>
        </div>
            ))
        } */}

        <div className='bento-container'>



  {
    dataArr?.map((item, index)=>(
        <article>
    <img src={item.imgPath} />
    <h3>{item.title}</h3>
    <ul>
    {
                        item?.points?.map((item, index)=>(
                            <li key={index}>{item}</li>
                        ))
                    }
        
    </ul>
  </article>

            ))
  }
</div>

<div className='feature-wp'>
<h1>What we ensure to provide</h1>
<ul>
    <li>Out of the box ideas to conduct the tour cost – effectively & efficiently.</li>
    <li>
    Proper and schematic organization of event based on specific theme.
    </li>
    <li>
    All pre arrival to post arrival arrangements
    </li>
</ul>
</div>

<div className='feature-wp'>
<h1>What includes in our MICE services</h1>
<ul>
    <li>All prior arrangement with perfection.</li>
    <li>Planning the travel.</li>
    <li>Prerequisite visit before the tour.</li>
    <li>Standardized services.</li>
    <li>Choice of airlines & booking tickets.</li>
    <li>Comfortable & convenient mode of conveyance.</li>
    <li>Prepare visa & other ticketing services.</li>
    <li>Stay at best hotel at best price.</li>
    <li>Foreign exchange, transfers, insurance & phone cards.</li>
    <li>Balance diet each day.</li>
    <li>Technical supports.</li>
    <li>Sightseeing tours.</li>
</ul>
</div>
    </section>
    </>
  )
}

export default Benifits