import React from 'react'
import "./ourFeatures.css"
function OurFeatures() {
  return (
    <div className='OurFeatures'>
        <h1 className='lora-font of-header'>Our Features</h1>
        <div className='of-body'>

          <div className='f-card'>
            <div className='fc-header'>
              <div className='fc-num-wp'>
                <span className='lora-font text'>01</span>
              </div>
              <span className='lora-font text'>Dynamic Packaging</span>
            </div>
            <div className='fc-body'>
              <p className='lora-font text'>
              Dynamic Packaging is a method used in package holiday bookings to enable consumers to bulid their package
              </p>
            </div>
          </div>

          <hr className='divider'></hr>

          <div className='f-card'>
            <div className='fc-header'>
              <div className='fc-num-wp'>
                <span className='lora-font text'>02</span>
              </div>
              <span className='lora-font text'>Instant Itineary</span>
            </div>
            <div className='fc-body'>
              <p className='lora-font text'>
              Dynamic Packaging is a method used in package holiday bookings to enable consumers to bulid their package
              </p>
            </div>
          </div>

          <hr className='divider'></hr>

          <div className='f-card'>
            <div className='fc-header'>
              <div className='fc-num-wp'>
                <span className='lora-font text'>03</span>
              </div>
              <span className='lora-font text'>Multiple Verticals</span>
            </div>
            <div className='fc-body'>
              <p className='lora-font text'>
              Dynamic Packaging is a method used in package holiday bookings to enable consumers to bulid their package
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default OurFeatures