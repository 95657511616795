import React, { useEffect } from 'react'
import Navbar from '../../components/navBar/Navbar'
import "./mice.css"
import Banner from './banner/Banner'
import HistorySection from './historySection/HistorySection'
import ContactCardSection from '../../components/contactCardSection/ContactCardSection'
import Footer from '../../components/footer/Footer'
import Benifits from './benifits/Benifits'
import ClientSection from '../home/ClientSection/ClientSection'
import PhotoGallary from './photoGallary/PhotoGallary'
function Mice() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='mice-page'>
        <Navbar type={"mice"} />
        <Banner />
        <ContactCardSection />
        <HistorySection />
        <Benifits />
        <ClientSection />
        <PhotoGallary />
        <Footer />
    </div>
  )
}

export default Mice