import React from 'react'
import "./tag.css"
function Tag({text="NULL"}) {
  return (
    <div className='comp-tag-wp'>
        {text}
    </div>
  )
}

export default Tag