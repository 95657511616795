export const contactData = [
    {
        location: "Kolkata",
        contactPrsn: "Vishwajeet Singh",
        phoneNum: "+91 9883107813",
        email: "vishwajeet@vaanyam.in",
        address: "3rd Floor, Raj Premier Building, EN37, Salt Lake Sector V, Kolkata - 700091"
    },
    {
        location: "Lucknow",
        contactPrsn: "Vishwajeet Singh",
        phoneNum: "+91 9883107813",
        email: "rishabh@vaanyam.in",
        address: "508, Block-BH5, Sector C5 Sushant Golf City, Lucknow- 226030"
    },
    {
        location: "Gaya, Bihar",
        contactPrsn: "Sanket Ranjan",
        phoneNum: "+91 8083211813",
        email: "sanket@vaanyam.in",
        address: "Kharkhura Main Road, Beside Kanya Primary School, Gaya, Bihar, 823002"
    },
    {
        location: "Noida",
        contactPrsn: "Vishwajeet Singh",
        phoneNum: "+91 9883107813",
        email: "vishwajeet@vaanyam.in",
        address: "D-9, Ground Floor, Sector 3, Noida, Goutam, Buddha Nagar, Noida 201301"
    },
    {
        location: "Varanasi",
        contactPrsn: "Atmaram Shukla",
        phoneNum: "+91 9870545461",
        email: "arshukla@vaanaym.in",
        address: "Shivaji Nagar Colony, Bhagwanpur Road, Varanasi, Varanasi, Uttar Pradesh, 221005"
    }
]