import React from 'react'
import "./historySection.css"
function HistorySection() {
  return (
    <div className='history-section'>
        <h1 className='title'>HISTORY OF EXCILENCE</h1>
        <img className='' src='../../../../mice/bis-hist.webp' alt='vaanyam business history' />
    </div>
  )
}

export default HistorySection