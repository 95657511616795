import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../../customHooks/useWindowDimensions'
import DestCard from '../../../components/destCard/DestCard'
import { Button } from '@mui/material'
import SearchInput from '../../../components/searchInput/SearchInput'

function DestContainer({destArr, locType}) {

    const [searchList, set_searchList] = useState([])
    const [filterData, set_filterData] = useState([...destArr])
    const [showMoreToggle, set_showMoreToggle] = useState(false)
    const [searchTerm, set_searchTerm] = useState("")
    const { height, width } = useWindowDimensions();
    let dummyArr = ["", "", "", ""]
    useEffect(() => {
      let setobj = new Set()
      destArr.forEach(item => {
        setobj.add(item.place)
        setobj.add(item.map)
      });
      set_searchList(Array.from(setobj).sort())
      set_filterData(destArr)
      console.log(destArr);
    }, [destArr])

    useEffect(() => {
        if(searchTerm){
            set_filterData(destArr.filter(item=>{
                return (item.place === searchTerm || item.map === searchTerm)
            }))
        }else{
            set_filterData(destArr)
        }
    }, [searchTerm])

    const handle_showMore = ()=>{
        set_showMoreToggle(!showMoreToggle)
    }
  return (
    <><div id={locType === "D"? "Domestic": "International"} className='locTyp-wp'>
    <div>
    <h3 className='text'>{locType === "D"? "Domestic": "International"}</h3>
    <SearchInput set_searchTerm={set_searchTerm} searchList={searchList} />  
    </div>
    <hr />
</div>
{(showMoreToggle && !searchTerm) && <Button onClick={handle_showMore} className='showLessBtn'>
        <p>Show less</p>
    </Button>}
<div className={`destCard-wp ${!showMoreToggle && "show-less"}`}>

    {   destArr?.length ?
        filterData.map((item, index)=>{
        return  (item.default || showMoreToggle || searchTerm) ?  <DestCard empty={false} data={item} /> : ""
        })
        : 
        dummyArr.map((item, index)=>{
        return  <DestCard empty={true} data={item} />
        })
    }

    {(!showMoreToggle && !searchTerm && Boolean(destArr?.length > 2)) && <Button onClick={handle_showMore} className='showMoreBtn'>
        <div>Show more</div>
    </Button>}
</div></>
  )
}

export default DestContainer