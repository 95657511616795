import React, { useEffect, useState } from 'react'
import "./navBar.css"
import Button from '@mui/material/Button';
import useWindowDimensions from '../../customHooks/useWindowDimensions';
// import { Link } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { phoneNumber } from '../../constant';
import PopUp from '../popUp/PopUp';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import About from '../about/About';
import { dark } from '@mui/material/styles/createPalette';

function CondComp({comp}){
    if(comp === "About Us") return <About />
    else if(comp === "MICE") return <p>Need Data</p>
}

function Navbar({type}) {
    const navigate = useNavigate()
    const [popUpState, set_popUpState] = useState({
        open: false,
        comp: ""
    })
    const { height, width } = useWindowDimensions();
    const [navBarToggle, set_navBarToggle] = useState(false)
    const [currentSelection, set_currentSelection] = useState(0)
    useEffect(() => {
      console.log(width);
    }, [width])

    const handle_NavBarToggle = ()=>{
        set_navBarToggle(!navBarToggle)
    }
    
  return (
    <div className={"Navbar" + ((width <=930 && !navBarToggle)? " mob-Navbar": "")}>
        <div className='nav-brand'>
            <span onClick={()=>navigate("/")}>
            <img className='brand-logo' src="../../../img/brandLogo.png" />
            </span>
            {width <= 930? 
            type === "home"? 
            <div className='toggle-btn' onClick={handle_NavBarToggle}>
            {navBarToggle? <span  className="material-symbols-outlined nav-menu-icon">
                close
            </span>:
            <span className="material-symbols-outlined nav-menu-icon">
                menu
            </span>}

            </div>: 
            <div >
            <Button className='nav-contactBtn' >
                <Link target='_blank' to={"https://wa.me/"+phoneNumber}>Contact Us</Link>
            </Button>
            </div>

            : ""}
        </div>
        {
            type === "home" ?
            <div className={"nav-tabs"}>
            <AnchorLink href={"#homesection"}>
                <span 
                className={currentSelection === 0? "darkBlue": "lightBlue"}
                onClick={()=>set_currentSelection(0)}>
                    Home
                </span>
            </AnchorLink>
            <span 
            className={currentSelection === 1? "darkBlue": "lightBlue"}
            onClick={()=>{set_popUpState({open: true, comp: "About Us"}); set_currentSelection(1)}}>
                About Us
            </span>
            <AnchorLink href={"#Domestic"}>
                <span 
                className={currentSelection === 2? "darkBlue": "lightBlue"}
                onClick={()=>set_currentSelection(2)}>
                    Domestic
                </span>
            </AnchorLink>
            <AnchorLink href={"#International"}>
                <span 
                className={currentSelection === 3? "darkBlue": "lightBlue"}
                onClick={()=>set_currentSelection(3)}>
                    International
                </span>
            </AnchorLink>
            <AnchorLink href={"#ClientSection"}>
                <span 
                className={currentSelection === 4? "darkBlue": "lightBlue"}
                onClick={()=>set_currentSelection(4)}>
                    Clients
                </span>
            </AnchorLink>


            <span
            onClick={()=>navigate("/mice")} 
            className={currentSelection === 5? "darkBlue": "lightBlue"}
            >
                MICE
            </span>

            <Link target='_blank' to={"https://vaanyam-admin.web.app"}>
                <span>
                    Admin
                </span>
            </Link>
        </div>:
        <div style={{display: "none"}} className={"nav-tabs"}>
            

        </div>
        }
        <div className='nav-contactBtn-wp'>

            <Button className='nav-contactBtn light-btn' >
                <Link target='_blank' to={"https://wa.me/"+phoneNumber}>Contact Us</Link>
            </Button>
        </div>
        <PopUp titleStyle={{fontSize: "1.5rem"}} title={popUpState.comp} open={popUpState.open} onClose={()=>{set_popUpState({open: false, comp: ""});
        set_currentSelection(-1)}} >
            <CondComp comp={popUpState.comp} />
        </PopUp>
    </div>
  )
}

export default Navbar