import React from 'react'
import "./about.css"
import Benifits from "../../pages/mice/benifits/Benifits"
import PhotoGallary from '../../pages/mice/photoGallary/PhotoGallary'
function About() {
  return (
    <div className='about'>
        <div className='block1'>
        <div className='text'>
        <p className=''>
            Watch the beauty of India and the World through a different lens! Begin your next adventure now only with Vaanyam Special Tour Packages. 
            </p>
            <p className=''>The Vaanyam Special Tour Packages are based on various themes like Air Inclusive Tour Packages, Wildlife Safaris, Veg Special tours, Spiritual Journeys, Bike Trips and Trekking Tour Packages. Enjoy the handcrafted tour packages covering various themes. </p>
        </div>
            <img className='img' src='../../../img/tnt_clipart.png' />
        </div>
        <div className='block2'>
        <img className='img' src='../../../img/tnt_bike.png' />
        <p className='text'>
            Whether exploring India on Bike Trips interests you or getting one with the beauty of nature with Trekking Tour Packages, Whether you want to enjoy the thrill of Wildlife Safaris or find your inner peace through the Spiritual Journeys, Whether you want to spend a hassle free holiday with the Air Inclusive Tour Packages or want the comfort of special food requirement through the Veg Special tours, we have got you covered. Simply select your preferred holiday through the wide range of options available and set out onto your next holiday.
            </p>
            
        </div>
        
        <div className='block3'>
        <Benifits clientPage={true} />
        <PhotoGallary />
        </div>
            {/* <img className='bottomImg' src='../../../img/tnt_clipart2.png' /> */}
    </div>
  )
}

export default About