import React, { useEffect, useState } from 'react'
import "./destinatons.css"
import DestCard from '../../../components/destCard/DestCard'
import useWindowDimensions from '../../../customHooks/useWindowDimensions'
import { dest } from './data'
import SearchInput from '../../../components/searchInput/SearchInput'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import DestContainer from './DestContainer'

const ScrollButtons = ()=>{
    return <div className='ds-scroll-btn-wp'>
    <div className='scrl-btn'>
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </div>
    <div className='scrl-btn'>
        <span class="material-symbols-outlined">
            arrow_forward_ios
        </span>
    </div>
</div>
}
function Destinatons({allDest=[]}) {

    // const [searchList, set_searchList] = useState([])
    // const [filterData, set_filterData] = useState([...allDest])
    // const [showMoreToggle, set_showMoreToggle] = useState(false)
    // const [searchTerm, set_searchTerm] = useState("")
    // const { height, width } = useWindowDimensions();
    // let dummyArr = ["", "", "", ""]
    // useEffect(() => {
    //   let setobj = new Set()
    //   allDest.forEach(item => {
    //     setobj.add(item.place)
    //     setobj.add(item.map)
    //   });
    //   set_searchList(Array.from(setobj).sort())
    //   set_filterData(allDest)
    // }, [allDest])

    // useEffect(() => {

    //     let setobj = new Set()
    //       dest.forEach(item => {
    //         setobj.add(item.place)
    //         setobj.add(item.map)
    //       });
    //       set_searchList(Array.from(setobj).sort())
    //       set_filterData(dest)
    //     }, [dest])


    


    
    return (
        <div id='destSection' className='destinatons lora-font'>
            <div className='ds-header'>
                <h1 className='text'>Find Popular
                    Destination</h1>
                {/* {width > 730 && <ScrollButtons />} */}
            </div>
            <DestContainer locType={"D"} destArr={allDest.filter(item=>item.locType === "D")} />
            <DestContainer locType={"I"} destArr={allDest.filter(item=>item.locType === "I")} />
            {/* {width < 730 && <ScrollButtons />} */}
        </div>
    )
}

export default Destinatons