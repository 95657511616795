import React, { useEffect, useState } from 'react'
import "./home.css"
import Navbar from '../../components/navBar/Navbar'
import Banner from './banner/Banner'
import OurFeatures from './ourFeatures/OurFeatures'
import Services from './services/Services'
import OurStories from './ourStories/OurStories'
import Destinatons from './destinatons/Destinatons'
import Footer from '../../components/footer/Footer'
import VideoSection from './videoSection/VideoSection'
import ContactCardSection from "../../components/contactCardSection/ContactCardSection"
import axios from 'axios'
import { API_PREFIX } from '../../constant'
import ClientSection from './ClientSection/ClientSection'
export default function Home() {
  const [allDest, set_allDest] = useState([])
  const fetchDest = async ()=>{
    await axios.get(API_PREFIX+"/dest/getall")
    .then((res)=>{
      if(res?.data?.success){
        console.log(res.data);
        let apiData = res?.data?.data
        set_allDest(apiData)
      }
    }).catch((err)=>{
      console.log(err);
      // alert("Something went wrong while fetching data, refresh the page")
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchDest()
  }, [])

  
  return (
    <div className='Home'>
        <Navbar type={"home"} />
        <VideoSection />
        <Banner />
        <OurFeatures />
        <Services />
        <OurStories />
        <Destinatons allDest={allDest} />
        <ContactCardSection />
        <ClientSection />
        <Footer />
    </div>
  )
}
