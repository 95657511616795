import React from 'react'
import "./banner.css"
import ContactBar from '../../../components/contactBar/ContactBar';

function Banner() {
  return (
    <div id='homesection' className='Banner'>
        <div className='bn-1'>
            <div className='dis-logo'>
              <div className='circle'>
              <span class="material-symbols-outlined send-icon">
send
</span>
              </div>
              <span className='text lora-font'>Discover the world</span>
            </div>
            <div className='big-tagline'>
              <p className='text lora-font'>
                Embark on Unforgettable Adventures
              </p>
            </div>
            <div className='para-wp'>
              <p className='text lora-font'>
              Immerse yourself in the awe-inspiring beauty of nature as you hike through pristine mountain trails, dive into crystal-clear turquoise waters, or witness the majestic wildlife in their natural habitats.
              </p>
            </div>
            <ContactBar />
        </div>
        <div className='bn-2'>
            <img className='bn-2-image' src='../../../img/bn_2_img.png' alt='bn-2-img' />
            <img className='bn-2-airplane-icon' src='../../../img/airplane.png' alt='bn-2-airplane-icon' />
        </div>
    </div>
  )
}

export default Banner