import React from 'react'
import "./services.css"
function Services() {
  return (
    <div className='services'>
        <div className='content-wp lora-font'>
            <h1>
            What Services We Provide
            </h1>
            <div className='list-wp'>
                <div className='list'>
                    <h3 >01. Travel Plan</h3>
                    <p>Travel plans are not really on instrument themselves but a delivery mechanism or strategy for other mostly.</p>
                </div>
                <div className='list'>
                    <h3 >02. Flights Booking</h3>
                    <p >Discovery your next dream destination. find deals on domestic and international flights.</p>
                </div>
                <div className='list'>
                    <h3 >03. Accommodation</h3>
                    <p >Lodging, food and services or travelling space and related services usually used in plural</p>
                </div>
            </div>
        </div>
        <div className='img-wp'>
            <img src='../../../../img/serviceImg.png' />
        </div>

    </div>
  )
}

export default Services