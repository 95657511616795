import React from 'react'
import "./ourStories.css"
function OurStories() {
  return (
    <div className='ourStories'>
        <div className='os-img-wp'>
            <img src='../../../../img/ourServiceImg.png' />
        </div>
        <div className='os-content-wp lora-font'>
            <h1>
            Our Stories Have Adventures
            </h1>
            <p>we are experienced in bringing adventures to stay their journey, with all outdoor destinations in the world as our specialties, start your adventure now! Nature has already called you!</p>
            <div className='tag-wp'>
                <div className='tag'>
                    <h3>12k+</h3>
                    <p>Success</p>
                    <p>journey</p>
                </div>
                <div className='tag'>
                    <h3>16k+</h3>
                    <p>Awards </p>
                    <p>Winning</p>
                </div>
                <div className='tag'>
                    <h3>20+</h3>
                    <p>Years of </p>
                    <p>Experience</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurStories;