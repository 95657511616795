import './App.css';
import DestDetail from './pages/destDetail/DestDetail';
import Home from './pages/home/Home';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from "react-router-dom";
import Mice from './pages/mice/Mice';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        
        <Route exact path='/destdetail/:name' element={<DestDetail />} />
        <Route exact path='/mice' element={<Mice />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
