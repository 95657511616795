import React, { useState } from 'react'
import "./photoGallary.css"
import PhotoCard from '../../../components/photoCard/PhotoCard'

function Photos ({list}){
    const [moseHover, setMoseHover] = useState(false)
    return (
        <div onMouseEnter={()=>setMoseHover(true)}
        onMouseLeave={()=>setMoseHover(false)} class="container">

        <div data-text={list[0]?.title} style={{"--r":"-15"}} class="glass">
            <img src={list[0]?.imgPath} alt='img' />
        </div>
        <div data-text={list[1]?.title} style={{"--r":"5"}} class="glass">
            <img src={list[1]?.imgPath} alt='img' />
        </div>
        <div data-text={list[2]?.title} style={{"--r":"25"}} class="glass">
        <img src={list[2]?.imgPath} alt='img' />
        </div>
        {
            moseHover && list?.map((item, index)=>{
                return index >= 3 ?(
                    <div key={index} data-text={item?.title} style={{"--r":"0"}} class={"glass"}>
        <img src={item?.imgPath} alt='img' />
        </div>
                ):""
            })
        }

        </div>
    )
}

function PhotoGallary() {
    const corpPhotoList = 
    [
        {
            imgPath: "../../../../mice/corp/argo.webp",
            title: "Agro Annual Dealer Conf."
        },
        {
            imgPath: "../../../../mice/corp/begotten.webp",
            title: "Begotten Annual Conf."
        },
        {
            imgPath: "../../../../mice/corp/zvdus-doc-conf.webp",
            title: "Zvdus Doctors Conf."
        },
        {
            imgPath: "../../../../mice/corp/spcl-annual-awart-evt.webp",
            title: "SPCL Annual Award Event"
        },
        {
            imgPath: "../../../../mice/corp/kamdhenu.webp",
            title: "Kamdhenu Dealer Conf."
        },
        {
            imgPath: "../../../../mice/corp/zydus-pharma.webp",
            title: "Zydua Pharma. Training Prog."
        },

    ]

    const tourPhotoList = [
        {
            imgPath: "../../../../mice/tour/bali.webp",
            title: "Bali Tour"
        },
        {
            imgPath: "../../../../mice/tour/thailand.webp",
            title: "Thailand Tour"
        },
        {
            imgPath: "../../../../mice/tour/dubai.webp",
            title: "Dubai Tour"
        },
        {
            imgPath: "../../../../mice/tour/kashmir.webp",
            title: "Kashmir Tour"
        },
        {
            imgPath: "../../../../mice/tour/kualalampur.webp",
            title: "Kualalampur Tour"
        },
        {
            imgPath: "../../../../mice/tour/naintal.webp",
            title: "Naintal Tour"
        },
        {
            imgPath: "../../../../mice/tour/dubai_2.webp",
            title: "Dubai Tour"
        },
    ]
  return (
    <div className='photoGallary'>
        <div className='gallary-wp'>
            <div className='content'>
                <h1>CORPORATE EVENTS &
                CONFERENCES</h1>
            </div>
            <div className='photo-scroller'>
                <Photos list={corpPhotoList} />
            </div>
        </div>

        <div style={{flexDirection: "row-reverse"}} className='gallary-wp'>
            <div className='content'>
                <h1>CORPORATE EVENTS &
                CONFERENCES</h1>
            </div>
            <div className='photo-scroller'>
                <Photos list={tourPhotoList} />
            </div>
        </div>
    </div>
  )
}

export default PhotoGallary