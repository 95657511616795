import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "./popUp.css"
import { IconButton } from '@mui/material';



export default function PopUp({titleStyle, open, onClose, children, title, PopUpButtom}) {
  

  const handleClose = () => {
    onClose()
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{...titleStyle}}>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#444",
          }}
        ><span class="material-symbols-outlined">
cancel
</span></IconButton>
        <DialogContent>
          {children}
          
        </DialogContent>
        <DialogActions>
          {PopUpButtom && <PopUpButtom />}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}