import React from 'react'
import "./footer.css"
import ContactBar from '../contactBar/ContactBar'
import { Link } from 'react-router-dom'
import { FbUrl, LinkdinUrl, XUrl } from '../../constant'
function footer() {
  return (
    <div className='footer lora-font'>
        <div className='ft-brand-wp'>
 
            <img className='ft brand-logo' src="../../../img/brandLogo.png" />
            <p className='text2'>
            Travel plans are not really an instrument themselves but a delivery mechanism or strategy for other fascinating experiences, mostly adventures, self-discovery, or forming lasting memories.
            </p>
        </div>
        <div className='ft-social-wp'>
            <ContactBar />
            <h1 className='text'>Join Our Social Media :</h1>
            <div className='social-icon-wp'>
                <Link target='_blank' to={FbUrl} >
                    <img src='../../../img/fbIcon.png' className='social-icon' />
                </Link>
                <Link target='_blank' to={XUrl}>
                    <img src='../../../img/xIcon.png' className='social-icon' />
                </Link>
                <Link target='_blank' to={LinkdinUrl}>
                    <img src='../../../img/linkdinIcon.png' className='social-icon' />
                </Link>
            </div>
        </div>
    </div>
  )
}

export default footer