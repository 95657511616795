import React from 'react'
import "./contactCardSection.css";
import { contactData } from './data';
function ContactCardSection() {

  return (
    <div className='contactCardSection'>
    <h4>Corporate Branch:</h4>
        <div className='cards-wp'>
            {
                contactData.map((item)=>(
                    <>
                    <table className='contact-table'>
                <tbody>
                <tr>
                        <td className='key'>Location:</td>
                        <td>{item?.location}</td>
                    </tr>
                    <tr>
                        <td className='key'>Contact Person:</td>
                        <td>{item?.contactPrsn}</td>
                    </tr>
                    
                    <tr>
                        <td className='key'>Phone No.:</td>
                        <td>{item?.phoneNum}</td>
                    </tr>
                    <tr>
                        <td className='key'>Email:</td>
                        <td>{item?.email}</td>
                    </tr>
                    <tr>
                        <td className='key'>Address:</td>
                        <td>{item?.address}</td>
                    </tr>

                </tbody>
            </table>
        <hr className='divider contact-divider'></hr>
                    </>
                ))
            }

        </div>
        
    </div>
  )
}

export default ContactCardSection